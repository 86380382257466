























































































































































































































































































































import axios, { AxiosError, AxiosResponse } from 'axios';
import gql from 'graphql-tag';
import * as math from 'mathjs';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Ref, Watch } from 'vue-property-decorator';

import {
  AmountLite,
  CategorizationStatus,
  Category,
  Connection,
  ConnectionCategory,
  ConnectionStatus,
  Contact,
  ExchangeRateObject,
  Maybe,
  MultiValueTransactionInput,
  NetworkContactInput,
  NetworkFeeCategoryInput,
  Org,
  Providers,
  ReconciliationStatus,
  Transaction,
  TransactionLite,
  TransactionsResultLite,
  TxnLineLite,
  TxnLineOperation,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import TooltipSelect from '@/components/tooltip/TooltipSelect.vue';
import {
  looselyGetCategoryWithCode,
  multivalueTransactionDataFactory,
  splitToMultiValueTransactionItemInput,
} from '@/components/transactions/categorization/StandardCategorization/utilities';
import EditTransactionModal from '@/components/transactions/edit/EditTransactionModal.vue';
import inlineCategorization from '@/components/transactions/InlineCategorization.vue';
import UiPageHeader from '@/components/ui/UiPageHeader.vue';
import UiToggle from '@/components/ui/UiToggle.vue';
import Beta from '@/components/util/Beta.vue';
import { isTxnClosed } from '@/services/transactionServices';
import { CancelablePromise, CanceledError, makeCancelable } from '@/utils/CancelablePromise';
import { convertUnits, getMainUnitForCoin } from '@/utils/coinUtils';
import { parseParams } from '@/utils/endpointUrlUtil';
import { stringifyError } from '@/utils/error';
import { assertDefined, isDefined } from '@/utils/guards';

import { baConfig } from '../../../config';
import {
  ApiSvcTransactionStateTransitions,
  ApiSvcTransactionStateUpdateDTO,
  ApiSvcUpdateTransactionStatus200Response,
  TransactionsApi,
  TransactionsV2Api,
} from '../../../generated/api-svc';
import { BaseVue } from '../../BaseVue';
import CreateManualTransaction from '../../components/transactions/CreateManualTransaction.vue';
import SaveInline from '../../components/transactions/SaveInline.vue';
import TransactionTable from '../../components/transactions/TransactionTable.vue';
import UiButton from '../../components/ui/UiButton.vue';
import UiDataTable from '../../components/ui/UiDataTable.vue';
import UiDatePicker2 from '../../components/ui/UiDatePicker2.vue';
import UiDropdown from '../../components/ui/UiDropdown.vue';
import UiLoading from '../../components/ui/UiLoading.vue';
import UiModal from '../../components/ui/UiModal.vue';
import UiSelect from '../../components/ui/UiSelect.vue';
import UiSelect2 from '../../components/ui/UiSelect2.vue';
import UiTabs from '../../components/ui/UiTabs.vue';
import UiTextEdit from '../../components/ui/UiTextEdit.vue';
import UiTooltip from '../../components/ui/UiTooltip.vue';
import { TransactionCountQuery, WalletsQuery } from '../../queries/transactionsPageQuery';
import { ellipsis } from '../../utils/stringUtils';
import BulkEditTransactionModal from './BulkEditTransactionModal.vue';
import { transactionResponse, walletResponse } from './demo_txn';

const wellKnownAddresses: Record<string, string> = {
  'ROSE:OASIS1QRMUFHKKYYF79S5ZA2R8YGA9GNK4T446DCY3A5ZM': 'Oasis common pool',
  'ROSE:OASIS1QQNV3PEUDZVEKHULF8V3HT29Z4CTHKHY7GKXMPH5': 'Oasis fee accumulator',
};

@Component({
  computed: {
    ApiSvcTransactionStateTransitions() {
      return ApiSvcTransactionStateTransitions;
    },
  },
  components: {
    SaveInline,
    UiButton,
    UiDropdown,
    UiTooltip,
    UiLoading,
    UiSelect,
    UiTextEdit,
    UiDataTable,
    UiTabs,
    UiToggle,
    UiSelect2,
    CreateManualTransaction,
    inlineCategorization,
    BulkEditTransactionModal,
    TransactionTable,
    TooltipSelect,
    UiModal,
    Beta,
    UiDatePicker2,
    EditTransactionModal,
    UiPageHeader,
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoadingWallets',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
    transactionCounts: {
      query: TransactionCountQuery,
      variables() {
        const ignore = this.vars.transactionFilter.ignoreFilter;
        let walletIds =
          this.vars.transactionFilter.walletsFilter?.[0] !== 'All'
            ? this.vars.transactionFilter.walletsFilter
            : undefined;
        if (walletIds?.length > 10) {
          walletIds = walletIds.slice(0, 10);
        }
        if (this.$store.state.currentOrg) {
          return {
            pivotDate: this.pivotDateVar,
            orgId: this.$store.state.currentOrg.id,
            walletIds,
            ignore,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoadingCounts',
      notifyOnNetworkStatusChange: true,
      deep: true,
      fetchPolicy: 'no-cache',
    },
    connections: {
      query: gql`
        query GetConnections($orgId: ID!) {
          connections(orgId: $orgId, overrideCache: true) {
            id
            provider
            isDisabled
            isDeleted
            category
            name
            feeAccountCode
            isDefault
            lastSyncSEC
          }
        }
      `,
      variables() {
        return {
          orgId: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoadingConnections',
      update(data: { connections?: Connection[] }) {
        return (
          data.connections?.filter((x) => !x.isDeleted && x.category === ConnectionCategory.AccountingConnection) ?? []
        );
      },
    },
  },
})
export default class TransactionsNew3 extends BaseVue {
  // #region Core data / logic
  public transactionsLite: TransactionsResultLite = {
    txns: [],
    coins: [],
  }; // populated via restEndpoint

  public previousPromise: CancelablePromise<any> | null = null;

  declare transactionCounts?: any; // populated via apollo
  declare connections: Connection[]; // populated via apollo

  @Ref('createTransactionModal')
  public readonly createTransactionModal!: typeof CreateManualTransaction;

  public hasTransactionLoadError = false;
  public isLoadingWallets = 0;
  public isLoadingCounts = 0;
  public isLoadingTransactions = 0;
  public isLoadingConnections = 0;
  public isLoadingExchangeRates = 0;
  public showFilters = true;
  public searchToken = '';
  public setDirtyTxns: string[] = [];
  public displayMode = 'min';
  public vars = {
    transactionFilter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletsFilter: ['All'],
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  public coinLookup = new Map<string, string>();
  public networkLookup = new Map<string, string>();
  public selectedTxns: TransactionLite[] = [];
  public apiBaseUrl = process.env.VUE_APP_API_URL;
  public visibleHeaders = [...this.headers.filter((h) => h.defaultVisibility).map((h) => h.id)];
  public ReconciliationStatus = ReconciliationStatus;
  public txnToDelete: TransactionLite | null = null;
  public txnToEdit: TransactionLite | null = null;
  public deleteDialog = false;
  public editDialog = false;
  public isSoftLoading = 0;
  public wallets: Wallet[] = [];
  public countTab: string | null = null;
  public isNew = true;
  public headerHeight = '0px';
  public currentRateCallIndex = 0;
  public batchSize = 5;
  public batchToken = Date.now();

  public closeEditTransaction() {
    this.editDialog = false;
    this.txnToEdit = null;
  }

  public editSingleTransaction() {
    this.txnToEdit = this.selectedTxns[0];
    this.editDialog = true;
  }

  public editTransaction(txn: TransactionLite) {
    this.txnToEdit = txn;
    this.editDialog = true;
  }

  public get calcWallets() {
    return this.isDemo ? this.getStaticDemoWallets().data.wallets : this.wallets;
  }

  public get pivotDateVar() {
    return this.vars.transactionFilter?.pivotDate || undefined;
  }

  public get displayTxns() {
    return this.hasTransactionLoadError ? [] : (this.transactionsLite?.txns ?? []).filter(isDefined);
  }

  get coins() {
    return this.transactionsLite?.coins ?? [];
  }

  public get connectionList(): Connection[] {
    const categories = this.categories;
    const contacts = this.contacts;

    let connections = this.connections ?? [];

    if (
      (contacts?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false) ||
      (categories?.some((x: any) => !x.accountingConnectionId || x.accountingConnectionId === 'Manual') ?? false)
    ) {
      const manualAccountingConnection = {
        id: 'Manual',
        provider: Providers.Manual,
        status: ConnectionStatus.Ok,
      };
      connections = connections.concat(manualAccountingConnection);
    }

    if (connections && connections.length > 0) {
      return connections;
    } else {
      return [];
    }
  }

  public get headers() {
    return [
      {
        id: 'type',
        label: 'Type',
        defaultVisibility: true,
        defaultWidth: '52px',
        sortable: this.isDemo,
        filterable: this.isDemo,
      },
      {
        id: 'id',
        label: 'Transaction Id',
        defaultVisibility: true,
        defaultWidth: '128px',
        sortable: this.isDemo,
        filterable: this.isDemo,
      },
      {
        id: 'date',
        label: `Date (${moment.tz(this.preferredTimezone).format('z')})`,
        defaultVisibility: true,
        defaultWidth: '152px',
        sortable: this.isDemo,
        filterable: this.isDemo,
      },
      {
        id: 'wallets',
        label: 'Wallet',
        defaultVisibility: true,
        defaultWidth: '107px',
        sortable: this.isDemo,
        filterable: this.isDemo,
      },
      {
        id: 'from',
        label: 'From',
        defaultVisibility: true,
        defaultWidth: '142px',
        sortable: this.isDemo,
        filterable: this.isDemo,
        isStatic: true,
      },
      {
        id: 'to',
        label: 'To',
        defaultVisibility: true,
        defaultWidth: '142px',
        sortable: this.isDemo,
        filterable: this.isDemo,
        isStatic: true,
      },

      { id: 'tickers', label: 'Ticker', defaultVisibility: true, defaultWidth: '95px' },
      {
        id: 'amount',
        label: 'Amount',
        defaultVisibility: true,
        defaultWidth: '99px',
        sortable: this.isDemo,
        filterable: this.isDemo,
      },
      { id: 'metadata', label: 'Metadata', defaultVisibility: false, defaultWidth: '250px' },
      {
        id: 'fmv',
        label: `Fair Market Value (${this.fiat.currency})`,
        defaultVisibility: true,
        defaultWidth: '182px',
        isStatic: true,
      },
      {
        id: 'status',
        label: 'Status',
        defaultVisibility: true,
        defaultWidth: '64px',
        sortable: this.isDemo,
        filterable: this.isDemo,
        isStatic: true,
      },
    ];
  }

  get disabledSearch() {
    return (
      this.vars.transactionFilter.reconciliationFilter === 'All' || this.vars.transactionFilter.ignoreFilter === 'All'
    );
  }

  get preferredTimezone() {
    const useOrgTimezone = this.$store.state.currentOrg.displayConfig?.useOrgTimezone ?? false;
    return useOrgTimezone ? this.$store.state.currentOrg.timezone : moment.tz.guess();
  }

  public get fiat() {
    const baseCurrency = this.$store.state.currentOrg.baseCurrency ?? 'USD';
    return (
      this.$store.getters['fiats/FIATS']?.find(
        (fiat: { name: string; symbol: string }) => fiat.name === baseCurrency
      ) ?? {
        name: baseCurrency,
        symbol: '$',
      }
    );
  }

  public get isLoading() {
    return !!this.isLoadingTransactions || !!this.isSoftLoading;
  }

  public get walletItems() {
    return [{ id: 'All', name: 'All Wallets' }, ...this.wallets.map((w) => ({ id: w.id, name: w.name }))];
  }

  public onDateChange(date: string) {
    if (this.isValidDate(date)) {
      this.vars.transactionFilter.pivotDate = date;
      this.vars.paginationToken = undefined;
      this.refresh();
    }
  }

  isValidDate(dateString: string) {
    // Parse the date string into a Date object
    const dateObj = new Date(dateString);

    // Check if the date object is valid and the string matches the expected format
    return !isNaN(dateObj.getTime()) && /^\d{4}-\d{2}-\d{2}$/.test(dateString);
  }

  public async toggleFilters() {
    this.showFilters = !this.showFilters;
    await this.$nextTick();
  }

  public toggleUi(val: boolean) {
    this.$emit('toggleUi', val);
  }

  public get selectedTxnsSet(): Set<TransactionLite> {
    return new Set(this.selectedTxns);
  }

  public onSelectionChanged(txns: TransactionLite[]) {
    this.$set(this, 'selectedTxns', txns);
    this.showFilters = !(this.selectedTxns.length > 0);
  }

  public get categories(): Category[] {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public async refresh() {
    // apply this to activate the filters
    this.onSelectionChanged([]);

    await Promise.all([
      this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id),
      this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id),
      this.$apollo.queries.transactionCounts.refetch(),
      this.callRestEndpoint(),
    ]);
  }

  /**
   * fetch the contact information for a transaction
   *
   * passed through SaveInline to child components for auto-setting contact
   *
   * @param transaction the transaction that the contact is being fetched for
   * @return {string|undefined} the name or address of the contact for the transaction
   *
   */
  public getContact(transaction: Transaction): string | undefined {
    try {
      const selfAddresses: string[] = [];
      const networkId = transaction.networkId;
      this.$store.state.wallets.wallets?.map((wallet) => {
        wallet.addresses?.forEach((address) => {
          try {
            if (!address) throw new Error('no address');
            selfAddresses.push(address);
          } catch (error) {}
        });
      });

      let transactionContact;

      // get the transaction transfer logs
      const transferLogs = transaction.txnLogs?.filter(
        (log) => log?.type === 'transfer-log' && log.from?.address !== '0x0000000000000000000000000000000000000000'
      );

      if (!transferLogs) throw new Error('no transfer logs');

      // get the first transfer log
      const firstLog = transferLogs[0];

      if (!firstLog) return undefined;

      // get participant addresses from the logs
      const fromAddress = firstLog.from?.address;
      const toAddress = firstLog.to?.address;

      if (!fromAddress || !toAddress) return undefined;

      let contactAddress;

      // handle contact lookup
      if (!networkId) throw new Error('no networkId');

      switch (transaction.type) {
        case TxnType.Send:
          contactAddress = toAddress;
          transactionContact = this.lookupAddress(contactAddress, networkId, firstLog?.asset?.symbol);
          break;
        case TxnType.Receive:
          contactAddress = fromAddress;
          transactionContact = this.lookupAddress(contactAddress, networkId, firstLog?.asset?.symbol);
          break;

        default:
          // handle unknown transactions
          if (selfAddresses.map((address) => address.toLocaleLowerCase()).includes(fromAddress.toLocaleLowerCase())) {
            contactAddress = toAddress;
          } else {
            contactAddress = fromAddress;
          }
          transactionContact = this.lookupAddress(contactAddress, networkId, firstLog?.asset?.symbol);

          break;
      }

      return transactionContact;
    } catch (error) {}
  }

  public lookupAddress(address: string | null | undefined, networkId?: string, coin?: string | null) {
    const key = `${networkId?.toUpperCase() ?? 'UNKNOWN'}:${address?.toUpperCase() ?? ''}`;
    const contactKey = `${coin?.toUpperCase() ?? 'UNKNOWN'}:${address?.toUpperCase() ?? ''}`;
    return this.addressLookup[key] ?? this.addressLookup[contactKey] ?? (address ? ellipsis(address, 20) : 'Unknown');
  }

  public get addressLookup() {
    const contacts = this.contacts;
    const wallets = this.wallets;

    let result = wallets.reduce((a, x) => {
      x.addresses?.forEach((addr) => {
        if (x.name && addr) {
          const upperAddr = addr.toUpperCase();
          const network = x.networkId?.toUpperCase() ?? 'UNKNOWN';
          if (upperAddr.startsWith('OASIS') && !upperAddr.endsWith(':ESCROW') && !a[`${network}:${upperAddr}:ESCROW`]) {
            a[`${network}:${upperAddr}:ESCROW`] = '(Escrow) ' + x.name;
          }
          a[`${network}:${upperAddr}`] = x.name;
        }
      });
      return a;
    }, {} as Record<string, string>);

    result = contacts.reduce((a, x) => {
      for (const addr of x.addresses ?? []) {
        if (addr?.address) {
          a[`${addr.networkId?.toUpperCase() ?? 'UNKNOWN'}:${addr.address.toUpperCase()}`] = x.name;
        }
      }
      return a;
    }, result);

    return { ...result, ...wellKnownAddresses };
  }

  public async execDeleteSelectedTransactions() {
    const combinedTxn = this.selectedTxns.find((txn: TransactionLite) => txn.isCombined);
    if (combinedTxn) {
      this.showErrorSnackbar(this.$tc('_cannotDeleteCombinedTransaction'));
      return;
    }

    const hasConfirmation = confirm('Are you sure you want to delete selected transactions?');
    if (!hasConfirmation) {
      return;
    }

    try {
      this.isSoftLoading++;
      for (const s of this.selectedTxns) {
        const vars = {
          orgId: this.$store.state.currentOrg.id,
          id: s.id,
        };
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!) {
              deleteTransaction(orgId: $orgId, id: $id)
            }
          `,
          variables: vars,
        });

        if ((res.errors && res.errors.length > 0) || !res.data.deleteTransaction) {
          const errors = [];
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }

          this.showErrorSnackbar(this.$tc('_deletedTransactionFailure') + ': ' + JSON.stringify(errors.join('<br />')));
        }
      }

      this.showSuccessSnackbar(this.$tc('_deletedTransactionsSuccess', this.selectedTxns.length));
    } catch (err) {
      this.showErrorSnackbar(this.$tc('_deletedTransactionFailure') + ': ' + stringifyError(err));
    } finally {
      this.selectedTxns = [];
      await this.refresh();
      this.isSoftLoading--;
    }
  }

  public combineSelectedTransactions() {
    if (this.selectedTxns.length < 2) {
      throw new Error("Can't combine less than 2 transactions");
    }

    this.isSoftLoading++;
    const txnIds = [...this.selectedTxns].map((m) => m.id);
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      txnIds,
    };
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $txnIds: [ID]!) {
            combineTransactions(orgId: $orgId, txnIds: $txnIds) {
              id
            }
          }
        `,
        variables: vars,
      })
      .then((res) => {
        if (res.errors && res.errors.length > 0) {
          const errors = [];
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }

          this.showErrorSnackbar(
            this.$tc('_combinedTransactionsFailure', this.selectedTxns.length) +
              ': ' +
              JSON.stringify(errors.join('<br />'))
          );
        } else {
          this.showSuccessSnackbar(this.$tc('_combinedTransactionsSuccess', this.selectedTxns.length));
        }

        this.refresh();
      })
      .catch((err) => {
        this.showErrorSnackbar(
          this.$tc('_combinedTransactionsFailure', this.selectedTxns.length) + ': ' + JSON.stringify(err.message)
        );
      })
      .finally(() => {
        this.selectedTxns = [];
        this.isSoftLoading--;
      });
  }

  public async setIgnoreSelectedTransactions(ignored: boolean) {
    if (this.selectedTxns.length === 0) {
      throw new Error('Need at least one transaction to ignore');
    }

    const txnIds = [...this.selectedTxns].map((m) => m.id);

    const errors = [];

    try {
      this.isSoftLoading++;
      for (const txnId of txnIds) {
        // const vars = ;

        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation ($orgId: ID!, $id: ID!, $ignore: Boolean!) {
              updateTransactionIgnoreStatus(orgId: $orgId, id: $id, ignore: $ignore) {
                id
              }
            }
          `,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            id: txnId,
            ignore: ignored,
          },
        });

        if (res.errors && res.errors.length > 0) {
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }
        }
      }
    } catch (err) {
      errors.push(stringifyError(err));
    } finally {
      this.selectedTxns = [];
      this.isSoftLoading--;
    }

    if (errors.length > 0) {
      const m = ignored ? '_ignoreTransactionsFailure' : '_unIgnoreTransactionsFailure';
      this.showErrorSnackbar(this.$tc(m, this.selectedTxns.length) + ': ' + JSON.stringify(errors.join('<br />')));
    } else {
      const m = ignored ? '_ignoreTransactionsSuccess' : '_unIgnoreTransactionsSuccess';
      this.showSuccessSnackbar(this.$tc(m, this.selectedTxns.length));
    }
    await this.refresh();
  }

  public async setReconcileSelectedTransactions(reconciliationStatus: ReconciliationStatus) {
    if (this.selectedTxns.length === 0) {
      throw new Error('Need at least one transaction to ignore');
    }

    const txnIds = [...this.selectedTxns].map((m) => m.id);

    const errors = [];

    try {
      this.isSoftLoading++;
      for (const txnId of txnIds) {
        // const vars = ;

        let mutation = null;

        if (reconciliationStatus === ReconciliationStatus.Reconciled) {
          mutation = gql`
            mutation ($orgId: ID!, $id: ID!) {
              markTransactionAsReconciled(orgId: $orgId, id: $id) {
                id
              }
            }
          `;
        } else {
          mutation = gql`
            mutation ($orgId: ID!, $id: ID!) {
              unreconcileTransaction(orgId: $orgId, id: $id) {
                id
              }
            }
          `;
        }

        const res = await this.$apollo.mutate({
          mutation,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            id: txnId,
          },
        });

        if (res.errors && res.errors.length > 0) {
          if (res.errors && res.errors.length > 0) {
            errors.push(...res.errors);
          }
        }
      }
    } catch (err) {
      errors.push(stringifyError(err));
    } finally {
      this.selectedTxns = [];
      this.isSoftLoading--;
    }

    if (errors.length > 0) {
      const m =
        reconciliationStatus === 'Reconciled' ? '_reconcileTransactionsFailure' : '_unReconcileTransactionsFailure';
      this.showErrorSnackbar(this.$tc(m, this.selectedTxns.length) + ': ' + JSON.stringify(errors.join('<br />')));
    } else {
      const m =
        reconciliationStatus === 'Reconciled' ? '_reconcileTransactionsSuccess' : '_unReconcileTransactionsSuccess';
      this.showSuccessSnackbar(this.$tc(m, this.selectedTxns.length));
    }
    await this.refresh();
  }

  public async uncategorizeTxns() {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      transactionIds: [...this.selectedTxns].map((txn) => txn?.id).filter((id) => id),
    };

    try {
      this.isSoftLoading++;
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation UncategorizeTransactions($orgId: ID!, $transactionIds: [ID!]!) {
            uncategorizeTransactions(orgId: $orgId, transactionIds: $transactionIds)
          }
        `,
        variables: vars,
      });

      if (res.data) {
        this.showSuccessSnackbar('Successfully Uncategorized Transactions');
        this.refresh();
      } else {
        throw Error('Problem Updating Transaction');
      }
    } catch (e) {
      this.showErrorSnackbar((e as Error).message);
    } finally {
      this.selectedTxns = [];
      this.isSoftLoading--;
    }
  }

  public async updateTxnState(transition: ApiSvcTransactionStateTransitions) {
    const orgId = this.$store.state.currentOrg.id;
    const tApi = new TransactionsV2Api(undefined, baConfig.getFriendlyApiUrl());
    const transactionIds = [...this.selectedTxns].map((txn) => txn?.id).filter((id) => id);

    const callPromises: Record<string, Promise<AxiosResponse<ApiSvcUpdateTransactionStatus200Response, any>>> = {};

    try {
      this.isSoftLoading++;
      const errors: Record<string, string> = {};

      for (const txn of this.selectedTxns) {
        const txnId = txn.id;
        if (!txnId) {
          throw new Error('Txn has unexpected undefined id');
        }

        let shouldFire = false;
        switch (transition) {
          case 'mark-ready-to-sync': {
            if (
              txn.reconciliationStatus !== ReconciliationStatus.Reconciled &&
              txn.categorizationStatus === CategorizationStatus.Categorized
            ) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction must be categorized not already synced';
            }
            break;
          }
          case 'ignore': {
            if (txn.ignored !== true) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction already ignored';
            }
            break;
          }
          case 'mark-synced': {
            if (txn.reconciliationStatus !== ReconciliationStatus.Reconciled) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction already ignored';
            }
            break;
          }
          case 'un-mark-synced': {
            // TODO-BABEL [pw]: This should actually be based off of state, not just reconciliation status
            if (txn.reconciliationStatus === ReconciliationStatus.Reconciled && txn.ignored !== true) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction must be synced and not ignored';
            }
            break;
          }
          case 'mark-not-ready-to-sync': {
            // TODO-BABEL [pw]: MUST be based off state
            if (txn.reconciliationStatus !== ReconciliationStatus.Reconciled) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction already fully synced';
            }
            break;
          }
          case 'un-ignore': {
            if (txn.ignored === true) {
              shouldFire = true;
            } else {
              errors[txnId] = 'Transaction not ignored';
            }
            break;
          }
        }

        if (shouldFire) {
          const update: ApiSvcTransactionStateUpdateDTO = {
            update: transition,
          };

          const p = tApi.updateTransactionStatus(orgId, txnId, update, { withCredentials: true });
          // TODO-BABEL @tyler Let's create a standard bulk update component that can take a structure like this and
          //   tick off all items with a progress
          callPromises[txnId] = p;
        }
      }
      await Promise.all(Object.values(callPromises));
      this.showSuccessSnackbar('Successfully Uncategorized Transactions');
      this.refresh();
    } catch (e) {
      this.showErrorSnackbar((e as Error).message);
    } finally {
      this.selectedTxns = [];
      this.isSoftLoading--;
    }
  }

  public ignoreTxn({ txn, ignore }: { txn: TransactionLite; ignore: boolean }) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
      ignore,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!, $ignore: Boolean!) {
            updateTransactionIgnoreStatus(orgId: $orgId, id: $id, ignore: $ignore) {
              id
            }
          }
        `,
        variables: vars,
      })
      .then(() => {
        this.refresh();
      })
      .finally(() => this.isSoftLoading--);
  }

  public reconcileTxn(txn: TransactionLite) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            markTransactionAsReconciled(orgId: $orgId, id: $id) {
              id
            }
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data) {
          this.showSuccessSnackbar('Successfully Reconciled Transaction');
          this.refresh();
        } else {
          this.showErrorSnackbar('Problem Reconciled Transaction');
          this.refresh();
        }
      })
      .catch((err) => {
        this.showErrorSnackbar('Error Reconciling ' + err);
      })
      .finally(() => this.isSoftLoading--);
  }

  public unreconcileTxn(txn: TransactionLite) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            unReconcileTransaction(orgId: $orgId, id: $id)
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data.unReconcileTransaction) {
          this.showSuccessSnackbar('Successfully Unreconciled Transaction');
          this.refresh();
        } else {
          this.showErrorSnackbar('Problem Unreconciling Transaction');
          this.refresh();
        }
      })
      .catch((err) => {
        this.showErrorSnackbar('Error Unreconciling ' + err);
      })
      .finally(() => this.isSoftLoading--);
  }

  public uncombineTxn(txn: TransactionLite) {
    if (!txn.isCombined) {
      this.showErrorSnackbar('Transaction must be combined');
      return;
    }

    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            unCombineTransaction(orgId: $orgId, id: $id)
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data.unCombineTransaction) {
          this.showSuccessSnackbar('Successfully Un-combined Transaction');
          this.refresh();
        } else {
          this.showErrorSnackbar('Problem Un-combining Transaction');
          this.refresh();
        }
      })
      .catch((err) => {
        this.showErrorSnackbar('Error Un-combining ' + err);
      })
      .finally(() => this.isSoftLoading--);
  }

  public deleteTxn(txn: TransactionLite) {
    if (txn.isCombined) {
      this.showErrorSnackbar(this.$tc('_cannotDeleteCombinedTransaction'));
      return;
    }
    this.txnToDelete = txn;
    this.deleteDialog = true;
  }

  public closeDelete() {
    this.txnToDelete = null;
    this.deleteDialog = false;
  }

  public execDeleteTxn() {
    if (this.txnToDelete === null) {
      this.showErrorSnackbar('Problem Deleting Transaction');
      return;
    }

    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: this.txnToDelete.id,
    };

    this.isSoftLoading++;
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $id: ID!) {
            deleteTransaction(orgId: $orgId, id: $id)
          }
        `,
        variables: vars,
      })
      .then((d) => {
        if (d.data.deleteTransaction) {
          this.showSuccessSnackbar('Successfully Deleted Transaction');
          this.refresh();
        } else {
          this.showErrorSnackbar('Problem Deleting Transaction');
          this.refresh();
        }
      })
      .catch((err) => {
        this.showErrorSnackbar('Error Deleting ' + err);
      })
      .finally(() => {
        this.closeDelete();
        this.isSoftLoading--;
      });
  }

  public async updateTransaction({
    txn,
    reconciliationStatus,
    categorizationStatus,
  }: {
    txn: TransactionLite;
    reconciliationStatus?: ReconciliationStatus;
    categorizationStatus?: CategorizationStatus;
  }) {
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      id: txn.id,
      reconciliationStatus,
      categorizationStatus,
    };

    // reconciliationStatus: ReconciliationStatus,
    //   categorizationStatus: CategorizationStatus

    try {
      this.isSoftLoading++;
      const d = await this.$apollo.mutate({
        mutation: gql`
          mutation (
            $orgId: ID!
            $id: ID!
            $reconciliationStatus: ReconciliationStatus
            $categorizationStatus: CategorizationStatus
          ) {
            updateTransaction(
              orgId: $orgId
              id: $id
              reconciliationStatus: $reconciliationStatus
              categorizationStatus: $categorizationStatus
            ) {
              id
            }
          }
        `,
        variables: vars,
      });
      if (d.data.updateTransaction) {
        this.showSuccessSnackbar('Successfully Updated Transaction');
        this.refresh();
      } else {
        this.showErrorSnackbar('Problem Updating Transaction');
        this.refresh();
      }
    } catch (err) {
      this.showErrorSnackbar('Error Updating ' + err);
    } finally {
      this.isSoftLoading--;
    }
  }

  createTransactionModalTrigger() {
    (this.createTransactionModal as any)?.trigger();
  }

  resetFilters() {
    this.countTab = null;
    this.vars.transactionFilter.categorizationFilter = 'All';
    this.vars.transactionFilter.reconciliationFilter = 'Unreconciled';
    this.vars.transactionFilter.ignoreFilter = 'Unignored';
    this.vars.transactionFilter.walletsFilter = ['All'];
    this.vars.transactionFilter.searchTokens = undefined;
    this.vars.transactionFilter.errored = undefined;
    this.searchToken = '';
    this.selectedTxns = [];
  }

  public get countTabs() {
    return [
      {
        label: 'Needs Categorization',
        value: 'categorize',
        count: this.isDemo ? 115 : this.transactionCounts.uncategorized,
      },
      {
        label: 'To Be Reconciled',
        value: 'reconcile',
        count: this.isDemo ? 90 : this.transactionCounts.unreconciled,
      },
      {
        label: 'All',
        value: 'all',
        count: this.isDemo ? 311 : this.transactionCounts.all,
      },
    ];
  }

  public onWalletsChange(wallets: string[]) {
    // if wallets is empty, set to all
    // also set to all if all is the last selection
    // but if all is selected and another wallet is selected last, remove all
    if (wallets.length === 0) {
      this.vars.transactionFilter.walletsFilter = ['All'];
    } else if (wallets[wallets.length - 1] === 'All') {
      this.vars.transactionFilter.walletsFilter = ['All'];
    } else if (wallets.includes('All')) {
      this.vars.transactionFilter.walletsFilter = wallets.filter((w) => w !== 'All');
    } else {
      this.vars.transactionFilter.walletsFilter = wallets;
    }
  }

  public onDisplayMode(mode: string) {
    localStorage.setItem('displayMode', mode);
    window.pendo?.track('Transactions - Update Display Mode', { mode });
  }

  public onVisibleHeaders(headers: string[]) {
    localStorage.setItem('visibleHeaders', JSON.stringify(headers));
    window.pendo?.track('Transactions - Update Visible Headers', { headers });
  }

  public async processNextBatch(txns: TransactionLite[], tApi: TransactionsApi, token: number): Promise<unknown> {
    if (token !== this.batchToken) {
      return Promise.resolve('Process aborted due to new query');
    }

    const batch = txns.slice(this.currentRateCallIndex, this.currentRateCallIndex + this.batchSize);
    const org = this.$store.state.currentOrg as Org | undefined;
    if (!org) {
      throw new Error('No current organization');
    }
    const responses = await Promise.allSettled(
      batch
        .filter((t) => !isTxnClosed(t, org))
        .map((t) => {
          return tApi.priceTransactionSec(this.$store.state.currentOrg.id, t.id || '', {
            withCredentials: true,
          });
        })
    );

    for (const res of responses) {
      switch (res.status) {
        case 'fulfilled': {
          const resVal = res.value;
          if (resVal.data.txn) {
            const existingTxn = (this.transactionsLite?.txns as TransactionLite[])?.find(
              (t: TransactionLite) => t.id === resVal.data.txn.id
            ) as TransactionLite;
            if (existingTxn) {
              existingTxn.exchangeRates = resVal.data.txn.exchangeRates as ExchangeRateObject;
              (existingTxn as any).typeGuess = resVal.data.txn.typeGuess;
            }
          }
          break;
        }
        case 'rejected': {
          if (res.reason.isAxiosError && res.reason.response?.data?.txn) {
            const axiosErr = res.reason as AxiosError;
            const errResp = axiosErr.response as AxiosResponse;
            const existingTxn = (this.transactionsLite?.txns as TransactionLite[])?.find(
              (t: TransactionLite) => t.id === errResp.data.txn.id
            ) as TransactionLite;
            if (existingTxn) {
              if (errResp.status === 409) {
                existingTxn.exchangeRates = {
                  exchangeRatesDirty: true,
                  exchangeRatesError: [`Too soon to price transaction, retry after ${errResp.data.retryAfter}`],
                };
              } else {
                existingTxn.exchangeRates = {
                  exchangeRatesDirty: true,
                  exchangeRatesError: [`Error pricing transaction ${errResp.status}-${errResp.statusText}`],
                };
              }
            }
          }
          break;
        }
      }
    }

    this.currentRateCallIndex += this.batchSize;
    if (this.currentRateCallIndex < txns.length) {
      await new Promise((resolve) => setTimeout(resolve, 5000));
      if (token !== this.batchToken) {
        return 'Process aborted due to new query';
      }
      return this.processNextBatch(txns, tApi, token);
    } else {
      return 'All rate batches processed';
    }
  }

  async callRestEndpoint() {
    this.isLoadingTransactions++;
    const variables = () => {
      if (this.$store.state.currentOrg) {
        const vars = {
          ...this.vars,
          orgId: this.$store.state.currentOrg.id,
          limit: typeof this.vars.limit === 'string' ? Number(this.vars.limit) : this.vars.limit,
        };
        // the wallets filter can only be 10 items long maximum
        if (vars.transactionFilter?.walletsFilter?.length > 10) {
          vars.transactionFilter.walletsFilter = vars.transactionFilter.walletsFilter.slice(0, 10);
        }
        return vars;
      } else {
        return false;
      }
    };

    const onResult = (data: TransactionsResultLite) => {
      this.currentRateCallIndex = 0;
      const tApi = new TransactionsApi(undefined, baConfig.getFriendlyApiUrl());
      const txns = data?.txns as TransactionLite[];
      this.isLoadingExchangeRates = 1;
      const newToken = Date.now();
      this.batchToken = newToken;
      const dirtyTxns = txns.filter((t) => !t.exchangeRates || t.exchangeRates.exchangeRatesDirty);
      if (dirtyTxns.length === 0) {
        this.isLoadingExchangeRates = 0;
      } else {
        this.processNextBatch(dirtyTxns, tApi, newToken).then(() => {
          this.isLoadingExchangeRates = 0;
        });
      }
    };

    const vars = variables();
    if (vars) {
      try {
        let dataUrl = `${this.apiBaseUrl}orgs/${this.$store.state.currentOrg.id}/transactions`;
        let queryParams = `pageLimit=${vars.limit}`;

        if (vars.paginationToken) {
          queryParams += `&paginationToken=${vars.paginationToken}`;
        }
        if (vars?.transactionFilter.categorizationFilter) {
          queryParams += `&categorizationFilter=${vars?.transactionFilter.categorizationFilter}`;
        }
        if (vars?.transactionFilter.reconciliationFilter) {
          queryParams += `&reconciliationFilter=${vars?.transactionFilter.reconciliationFilter}`;
        }
        if (vars?.transactionFilter.ignoreFilter) {
          queryParams += `&ignoreFilter=${vars?.transactionFilter.ignoreFilter}`;
        }
        if (vars?.transactionFilter.walletsFilter) {
          for (const w of vars?.transactionFilter.walletsFilter) {
            queryParams += `&walletFilter=${w}`;
          }
        }
        if (vars?.transactionFilter.searchTokens && !this.disabledSearch) {
          for (const t of vars?.transactionFilter.searchTokens) {
            console.log({ t });
            queryParams += `&searchTokens=${t}`;
          }
        }
        if (vars?.transactionFilter.errored) {
          queryParams += `&errored=${vars?.transactionFilter.errored}`;
        }
        if (vars?.transactionFilter.pivotDate) {
          queryParams += `&pivotDate=${vars?.transactionFilter.pivotDate}`;
        }

        queryParams += `&timezone=${this.preferredTimezone}`;

        // using URLSearchParams constructor to properly escape special characters
        dataUrl += `?${new URLSearchParams(queryParams)}`;

        if (this.previousPromise) {
          this.previousPromise.cancel();
        }
        const originalPromise = axios.get(dataUrl, {
          withCredentials: true,
        });
        const cancelablePromise = makeCancelable(originalPromise);
        this.previousPromise = cancelablePromise;
        const response = await cancelablePromise;

        if (response.status === 200) {
          if (this.isDemo) {
            this.uniqueColumnValues.type = this.getStaticUnique('type');
            this.uniqueColumnValues.id = this.getStaticUnique('txnId');
            this.uniqueColumnValues.wallets = this.getStaticUnique('wallet');
            this.uniqueColumnValues.date = this.getStaticUnique('date');
            this.uniqueColumnValues.amounts = this.getStaticUnique('amount');
            this.uniqueColumnValues.to = this.getStaticUnique('toAddress');
            this.uniqueColumnValues.from = this.getStaticUnique('fromAddress');
            this.uniqueColumnValues.status = this.getStaticUnique('status');
            response.data = this.getStaticDemoTransaction(
              vars.limit,
              0,
              this.filters.type as string[],
              this.filters.id as string[],
              this.filters.date as number[],
              this.filters.wallets as string[],
              this.filters.to as string[],
              this.filters.from as string[],
              this.filters.amounts as string[],
              this.filters.status as string[],
              this.sort.id,
              this.sort.asc ? 'asc' : 'desc'
            );
          }
          this.transactionsLite = response.data;
          onResult(response.data);
        }
        this.isLoadingTransactions--;
      } catch (error) {
        this.isLoadingTransactions--;
        if (error instanceof CanceledError) {
          console.log('The promise was canceled');
        } else {
          console.log('Some other error', error);
          this.showErrorSnackbar('Error Fetching Transactions ' + error);
          this.hasTransactionLoadError = true;
        }
      }
    }
  }

  async mounted() {
    const queryParams = this.$route.query;

    if (!Object.keys(queryParams).length) await this.callRestEndpoint();
    else this.applyRouteFilters(queryParams);

    await this.$nextTick();
    this.displayMode = localStorage.getItem('displayMode') ?? 'min';
    const visibleHeaders = localStorage.getItem('visibleHeaders');
    if (visibleHeaders) {
      this.visibleHeaders = [
        ...JSON.parse(visibleHeaders),
        ...this.headers.filter((x) => x.isStatic).map((x) => x.id),
      ].reduce((a, x) => {
        if (!a.includes(x)) {
          a.push(x);
        }
        return a;
      }, [] as string[]);
      if (this.visibleHeaders.includes('amounts')) {
        this.visibleHeaders.push('amount', 'tickers');
      }
    }
  }

  applyRouteFilters(params: any) {
    const routeFilters = parseParams(params);
    const { txFilter } = routeFilters as { txFilter: Record<string, any> };
    this.vars.limit = '250';
    this.vars.transactionFilter = { ...this.vars.transactionFilter, ...txFilter };
    if (txFilter.searchTokens && txFilter.searchTokens instanceof Array)
      this.searchToken = txFilter.searchTokens.join(' ');
  }

  @Watch('transactionsLite')
  public updateCoinLookup() {
    this.coinLookup = this.transactionsLite
      ? new Map(this.transactionsLite?.coins?.map((c: any) => [c.currencyId, c.ticker]))
      : new Map();
    this.networkLookup = this.transactionsLite
      ? new Map(this.transactionsLite?.coins?.map((c: any) => [c.currencyId, c.networkId]))
      : new Map();
  }

  private searchDebounceTimer?: number;
  @Watch('searchToken')
  public onSearchTokenChange() {
    if (this.searchDebounceTimer) {
      clearTimeout(this.searchDebounceTimer);
    }
    this.searchDebounceTimer = window.setTimeout(() => {
      this.searchDebounceTimer = undefined;
      // prevent reassignment if value has not changed, reassignment means another endpoint call
      if (this.searchToken !== this.vars.transactionFilter.searchTokens?.join(' ')) {
        this.vars.transactionFilter.searchTokens =
          this.searchToken.trim() !== '' ? this.searchToken.split(' ') : undefined;
      }
    }, 1000);
  }

  @Watch('countTab')
  public onCountTabChanged() {
    if (!this.countTab) return;

    if (this.countTab === 'categorize') {
      this.vars.transactionFilter.categorizationFilter = 'Uncategorized';
      this.vars.transactionFilter.reconciliationFilter = 'Unreconciled';
    } else if (this.countTab === 'reconcile') {
      this.vars.transactionFilter.categorizationFilter = 'Categorized';
      this.vars.transactionFilter.reconciliationFilter = 'Unreconciled';
    } else if (this.countTab === 'all') {
      this.vars.transactionFilter.categorizationFilter = 'All';
      this.vars.transactionFilter.reconciliationFilter = 'All';
    }
  }

  @Watch('vars')
  @Watch('vars.limit')
  @Watch('vars.transactionFilter.categorizationFilter')
  @Watch('vars.transactionFilter.reconciliationFilter')
  @Watch('vars.transactionFilter.ignoreFilter')
  @Watch('vars.transactionFilter.walletsFilter')
  @Watch('vars.transactionFilter.searchTokens')
  @Watch('vars.transactionFilter.errored')
  @Watch('vars.transactionFilter.pivotDate')
  @Watch('vars.paginationToken')
  async filtersUpdated() {
    window.pendo?.track('Transaction - Filters Updated', {
      categorizationFilter: this.vars.transactionFilter.categorizationFilter,
      reconciliationFilter: this.vars.transactionFilter.reconciliationFilter,
      ignoreFilter: this.vars.transactionFilter.ignoreFilter,
      walletsFilter: !!this.vars.transactionFilter.walletsFilter?.length,
      searchTokens: !!this.vars.transactionFilter.searchTokens?.length,
      errored: this.vars.transactionFilter.errored,
      pivotDate: this.vars.transactionFilter.pivotDate,
      limit: this.vars.limit,
      paginationToken: !!this.vars.paginationToken,
    });
    await this.callRestEndpoint();
  }

  @Watch('$store.state.currentOrg.id')
  async orgIdUpdated() {
    this.resetFilters();
  }
  // #endregion

  // #region inline categorization
  public inlineTransaction: TransactionLite | null = null;
  public inlineCostBasisType = 'exchangeRate';

  // current not used, just using defaultFeeCategoryId directly in txnTable
  public get feeCategories(): NetworkFeeCategoryInput[] {
    return this.$store.state.currentOrg.accountingConfig?.defaultFeeCategoryIds;
  }

  public get feeContacts(): NetworkContactInput[] {
    return this.$store.state.currentOrg.accountingConfig?.networkContactIds;
  }

  public async assignToContact({
    address,
    contactId,
    currencyId,
  }: {
    address: string;
    contactId: string;
    currencyId: string;
  }) {
    const contact = this.contacts.find((c) => c.id === contactId);
    if (!contact) return;
    await this.saveContactAddress(contact, address, currencyId);
  }

  // copy of the function in transactionTable (needs to be refactored to here and not duplicated)
  public isValidInline(txn: any) {
    return txn.txnLines.every(
      (x: any) =>
        x.category &&
        x.contact &&
        txn.accountingConnectionId &&
        (x.feeCategory || x.operation === TxnLineOperation.Fee || !x.feeAssetId) &&
        (x.feeContact || x.operation === TxnLineOperation.Fee || !x.feeAssetId)
    );
  }

  // copy of the function in transactionTable (needs to be refactored to here and not duplicated)
  public isDirty(txn: any) {
    return txn.txnLines.some(
      (x: any) =>
        ((x.contact || x.category || x.feeCategory || x.feeContact) &&
          txn.categorizationStatus === CategorizationStatus.Uncategorized) ||
        x.dirty
    );
  }

  public get showConfirmAll() {
    return this.displayTxns?.some(
      (txn: any) => this.isDirty(txn) && this.isValidInline(txn) && txn.useLines && txn.canInline
    );
  }

  public confirmAllInline() {
    const txns = this.displayTxns?.filter(
      (txn: any) => txn.useLines && txn.canInline && this.isDirty(txn) && this.isValidInline(txn)
    );
    txns?.forEach((txn: any) => this.inlineCategorize2(txn));
  }

  public async saveContactAddress(contact: Contact, address: string, currencyId: string) {
    this.isSoftLoading++;
    let addresses = (contact?.addresses as any[]) ?? [];
    const networkId = this.transactionsLite.coins.find((c) => c?.currencyId === currencyId)?.networkId;
    // addresses.push({ coin: ticker, address });
    addresses.push({ networkId, address });
    addresses = addresses.filter((item, index, array) => {
      // return array.findIndex((obj) => obj.coin === item.coin && obj.address === item.address) === index;

      return array.findIndex((obj) => obj.networkId === item.networkId && obj.address === item.address) === index;
    });
    try {
      await this.$apollo.mutate({
        mutation: gql`
          mutation ($orgId: ID!, $contactId: ID!, $addresses: [ContactAddressInput!]!) {
            setContactCoinAddresses(orgId: $orgId, contactId: $contactId, addresses: $addresses)
          }
        `,
        variables: {
          orgId: this.$store.state.currentOrg.id,
          contactId: contact.id,
          addresses: addresses.map((x) => ({ ...x, __typename: undefined, networkId: x.networkId ?? 'eth' })),
        },
      });
    } catch (e) {
      this.showErrorSnackbar('An error occurred');
      console.error(e);
      return;
    } finally {
      this.isSoftLoading--;
    }
  }

  public async assignCategoryToContact({
    contactId,
    revenue,
    categoryId,
  }: {
    contactId: string;
    revenue: boolean;
    categoryId: string | null;
  }) {
    const contact = this.contacts.find((c) => c.id === contactId);
    if (!contact) return;
    await this.updateDefaultCategory(contact, revenue, categoryId);
  }

  public updateDefaultCategory(contact: Contact, revenue: boolean, categoryId: string | null) {
    this.isSoftLoading++;
    const variables = {
      orgId: this.$store.state.currentOrg.id,
      contact: {
        id: contact.id,
        enabled: contact.enabled,
        defaultRevenueCategoryId: null,
        defaultExpenseCategoryId: null,
      },
    } as {
      orgId: string;
      contact: {
        id: string;
        enabled: boolean;
        defaultRevenueCategoryId: string | null;
        defaultExpenseCategoryId: string | null;
      };
    };
    if (revenue === true) {
      if (categoryId) variables.contact.defaultRevenueCategoryId = categoryId;
      variables.contact.defaultExpenseCategoryId = contact.defaultExpenseCategoryId ?? null;
    } else {
      if (categoryId) variables.contact.defaultExpenseCategoryId = categoryId;
      variables.contact.defaultRevenueCategoryId = contact.defaultRevenueCategoryId ?? null;
    }
    this.$apollo
      .mutate({
        mutation: gql`
          mutation ($orgId: ID!, $contact: UpdateContactInput!) {
            updateContact(orgId: $orgId, contact: $contact) {
              name
              enabled
              defaultExpenseCategoryId
              defaultRevenueCategoryId
            }
          }
        `,
        // Parameters
        variables,
      })
      .then((res) => {
        this.refresh();
        this.isSoftLoading--;
      })
      .catch((e) => {
        console.log(e);
        this.isSoftLoading--;
      });
  }

  public inlineCategorize2(txn: any) {
    this.inlineTransaction = txn;
    this.confirmInlineCategorizeTxn();
  }

  public confirmInlineCategorizeTxn() {
    const transaction = this.inlineTransaction;
    if (!transaction) return;
    const exchangeRates = [];
    for (const er of transaction.exchangeRates?.exchangeRates ?? []) {
      let exRate: any = {
        coin: er?.coin,
        unit: er?.coinUnit,
        fiat: er?.fiat,
        rate: er?.rate,
        // source: er?.source,
      };
      if (transaction.type === 'ContractExecution') {
        exRate = { ...exRate, priceId: er?.priceId };
      }
      exchangeRates.push(exRate);
    }

    let cb: any;
    if (transaction.exchangeRates?.exchangeRates) {
      if (this.inlineCostBasisType === 'exchangeRate') {
        cb = {
          exchangeRate: this.convertBigNumberScalar(exchangeRates[0].rate).toNumber(),
          costBasisType: 'ExchangeRate',
          valid: true,
          exchangeRates: exchangeRates,
        };
      }
    }
    const splits: any[] = [];
    this.calcPaidFees(transaction);
    const lines = this.calcLines(
      transaction,
      this.categories,
      this.connectionList.find((connection) => connection.id === transaction.accountingConnectionId) ?? null
    );

    // group inlineTransaction.txnLines by contact
    const groupedLines = transaction?.txnLines?.reduce((a, x: any) => {
      if (!x.contact) return a;
      if (x.category && x.amount) {
        a[x.contact] = [...(a[x.contact] || []), x];
      }
      if (!x.feeContact && x.feeCategory && x.feeAmount) {
        a[x.contact] = [...(a[x.contact] || []), { ...x, category: x.feeCategory, amount: x.feeAmount }];
      }
      if (x.feeContact) {
        a[x.feeContact] = [...(a[x.feeContact] || []), { ...x, category: x.feeCategory, amount: x.feeAmount }];
      }
      return a;
    }, {} as any);

    Object.keys(groupedLines).forEach((key) => {
      const lineGroup = groupedLines[key];
      const contact = key;
      splits.push({
        contact: this.contacts.find((c) => c.id === contact),
        lines: lineGroup.map((txnLine: any) => {
          const line = lines.find((x) => x.amount.abs().toString() === txnLine.amount);
          assertDefined(line);
          const category = this.categories.find((c) => c.id === txnLine.category);
          return {
            ...line,
            category,
            txnLineId: txnLine.txnLineId,
          };
        }),
      });
    });

    const baseCurrency = this.$store.state.currentOrg.baseCurrency;
    const splitsFinal = splits.map((split) =>
      splitToMultiValueTransactionItemInput(
        split,
        this.txnAmountTotal(transaction).toNumber(),
        baseCurrency,
        cb,
        transaction.type ?? ''
      )
    );
    const { valid, ...transactionData } = multivalueTransactionDataFactory(splitsFinal, exchangeRates);
    assertDefined(transactionData);
    const vars = {
      orgId: this.$store.state.currentOrg.id,
      txnId: transaction.id ?? '',
      transactionData: {
        ...transactionData,
        accountingConnectionId: transactionData.multivalue.items[0].contactId.split('.')[0],
      },
    };

    return this.categorizeTransaction(vars);
  }

  public txnAmountTotal(txn: TransactionLite): math.BigNumber {
    let amountTotal = math.bignumber(0);

    const amounts = txn.fullAmountSetWithoutFees;

    assertDefined(amounts);
    for (const m of amounts) {
      assertDefined(m);
      const { value, currencyId, unit: unitId } = m;
      const coinObj = this.getCoinByCurrencyId(currencyId, unitId);
      const coin = coinObj.ticker;
      const unit = coinObj.unit;
      assertDefined(coin);
      assertDefined(unit);
      const val = this.convertBigNumberScalar(value);
      const targetUnit = getMainUnitForCoin(coin);
      const converted = convertUnits(coin, unit, targetUnit, val);
      assertDefined(converted);
      amountTotal = amountTotal.plus(converted);
    }

    return amountTotal;
  }

  async categorizeTransaction(vars: {
    orgId: string;
    txnId: string;
    transactionData: {
      multivalue: MultiValueTransactionInput;
    };
  }): Promise<void> {
    try {
      this.isSoftLoading++;
      await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation ($orgId: ID!, $txnId: ID!, $transactionData: TransactionData!) {
            categorizeTransaction(orgId: $orgId, txnId: $txnId, transactionData: $transactionData) {
              id
            }
          }
        `,
        // Parameters
        variables: vars,
      });
      this.isSoftLoading--;
      this.showSuccessSnackbar('Successfully Categorized Transaction');
      this.setDirtyTxns.push(vars.txnId);
      this.refresh();
    } catch (e) {
      this.isSoftLoading--;
      const message = 'Problem saving item: ' + stringifyError(e, { hideErrorName: true });
      // this.$store.commit(MUT_SNACKBAR, {
      //   color: 'error',
      //   message,
      // });
    }
  }

  public calcPaidFees(txn: TransactionLite) {
    type IAmount = { coin: string; displayValue: string; unit: string; value: string; __typename: string };
    const isSameUser = (a: IAmount, b: IAmount) =>
      `${a.coin}${a.displayValue}${a.unit}${a.value}${a.__typename}` ===
      `${b.coin}${b.displayValue}${b.unit}${b.value}${b.__typename}`;

    // Get items that only occur in the left array,
    // using the compareFunction to determine equality.
    const onlyInLeft = (left: any, right: any, compareFunction: any) =>
      left.filter((leftValue: any) => !right.some((rightValue: any) => compareFunction(leftValue, rightValue)));

    const fees = onlyInLeft(txn.fullAmountSet, txn.fullAmountSetWithoutFees, isSameUser);
    txn.paidFees = fees;
  }

  public calcLines(
    txn: TransactionLite,
    categories: Category[],
    connection: Connection | null
  ): { amount: math.BigNumber }[] {
    const prePopLines: any[] = [];
    if (txn?.txnLines?.length) {
      txn.txnLines.forEach((a) => {
        const l = this.prepopulateLineFromTxnLine(a);

        prePopLines.push(l);
      });
    } else if (txn && txn.fullAmountSetWithoutFees && txn.paidFees?.length) {
      //  we are going to try to pre populate the txn amounts and fees separately
      //  we will try to fill in the fee contact and category
      txn.fullAmountSetWithoutFees.forEach((a: any) => {
        const l = this.prepopulateLineFromAmount(a);
        prePopLines.push(l);
      });

      txn.paidFees.forEach((f: any) => {
        const l = this.prepopulateLineFromAmount(f);

        const maybeCategory = looselyGetCategoryWithCode(categories, connection?.feeAccountCode);

        const fee = {
          category: maybeCategory,
          amount: l.amount as any,
          ticker: l.ticker,
          description: `Fee for txn ${txn.id}.`,
        };
        prePopLines.push(fee);
      });
    } else if (txn && txn.fullAmountSet) {
      //  if we failed to do txn amount and fees separately
      //  then we are just going to do the full set
      txn.fullAmountSet.forEach((a: any) => {
        const l = this.prepopulateLineFromAmount(a);
        prePopLines.push(l);
      });
    } else if (txn && txn.fullAmountSetWithoutFees && txn.fullAmountSetWithoutFees.length > 0) {
      //  this is in case we do not have fullAmountSet
      const amounts = txn.fullAmountSetWithoutFees;
      assertDefined(amounts);
      amounts.forEach((a: any) => {
        const l = this.prepopulateLineFromAmount(a);
        prePopLines.push(l);
      });
    }
    //  this is the final catch all
    if (prePopLines.length === 0) {
      const zero = math.bignumber(0);
      prePopLines.push({ amount: zero });
    }
    return prePopLines;
  }

  public prepopulateLineFromTxnLine(txnLine: Maybe<TxnLineLite>) {
    assertDefined(txnLine);
    const { amount, assetId, operation, walletId, id } = txnLine;
    assertDefined(amount);
    assertDefined(assetId);
    assertDefined(operation);
    const coinObj = this.getCoinByCurrencyId(assetId);
    const coin = coinObj.ticker;
    assertDefined(coin);
    const currencyString = String(assetId).substring(0, 4).toUpperCase();
    const unit = currencyString === 'FIAT' ? coin : coinObj.unit;
    assertDefined(unit);
    const mainUnit = getMainUnitForCoin(coin);
    const val = math.bignumber(operation === 'DEPOSIT' ? amount : '-' + amount);
    const conv = convertUnits(coin, unit, mainUnit, val);
    return {
      amount: conv,
      ticker: coin,
      walletId: walletId,
      isFee: operation === 'FEE',
      description: operation === 'FEE' ? `Fee for txn ${id}.` : undefined,
    };
  }

  public prepopulateLineFromAmount(amount: Maybe<AmountLite>) {
    assertDefined(amount);
    const { currencyId, value, unit: unitId } = amount;
    const coinObj = this.getCoinByCurrencyId(currencyId, unitId);
    const coin = coinObj.ticker;
    const unit = coinObj.unit;
    assertDefined(coin);
    assertDefined(unit);
    const mainUnit = getMainUnitForCoin(coin);
    const val = this.convertBigNumberScalar(value);
    const conv = convertUnits(coin, unit, mainUnit, val);
    return {
      amount: conv,
      ticker: coin,
    };
  }

  public getCoinByCurrencyId(ci: string, unit?: string) {
    const coin = { ...this.coins.find((coin) => coin?.currencyId === ci) } as any;

    switch (unit) {
      case '1':
        coin.unit = 'Satoshi';
        break;
      case '2':
        coin.unit = 'Bitcoin';
        break;
      case '10':
        coin.unit = 'Wei';
        break;
      case '11':
        coin.unit = 'Ether';
        break;
      case '20':
        coin.unit = 'EOS';
        break;
      case '999999':
        coin.unit = 'Unknown';
        break;
      default: {
        break;
      }
    }
    return coin;
  }

  // #endregion

  // #region bulk categorization
  public showDoneModal = false;

  get allowBulkCategorize() {
    return true;
  }

  public closeDoneModal(extraAction?: string) {
    this.showDoneModal = false;
    if (extraAction === 'refresh') {
      this.setDirtyTxns = [...this.setDirtyTxns, ...this.selectedTxns.map((item) => item.id ?? '')];
      this.selectedTxns = [];
      this.refresh();
    }
  }

  public bulkEditTransaction() {
    this.showDoneModal = true;
  }
  // #endregion

  // #region demo / filter and sorting
  public get isDemo() {
    return this.checkFeatureFlag('filter-demo', this.$store.getters.features);
  }

  filterStaticTxns(field: string, values: Array<string | number>, txns: any[]) {
    // const txns = transactionResponse().txns;
    if (values.length === 0) {
      return txns;
    }
    switch (field) {
      case 'type': {
        return txns.filter((t) => {
          return values.includes(t.type);
        });
      }
      case 'txnId': {
        return txns.filter((t) => {
          return values.includes(t.id);
        });
      }
      case 'date': {
        return txns.filter((t) => {
          return values.includes(this.splitDate(t.created)[0]);
        });
      }
      case 'wallet': {
        return txns.filter((t) => {
          const wallets = (t.txnLines as any[]).map(
            (l) => this.getStaticDemoWallets().data.wallets.find((w) => w.id === l.walletId)?.name
          );
          for (const w of wallets) {
            if (values?.includes(w as string)) {
              return true;
            }
          }
          return false;
        });
      }
      case 'toAddress': {
        return txns.filter((t) => {
          const addresses = (t.txnLines as any[]).map((l) => l.to);
          for (const a of addresses) {
            if (values?.includes(a)) {
              return true;
            }
          }
          return false;
        });
      }
      case 'fromAddress': {
        return txns.filter((t) => {
          const addresses = (t.txnLines as any[]).map((l) => l.from);
          for (const a of addresses) {
            if (values?.includes(a)) {
              return true;
            }
          }
          return false;
        });
      }
      case 'amount': {
        return txns.filter((t) => {
          const amounts = (t.txnLines as any[]).map((l) => l.amount);
          for (const a of amounts) {
            if (values?.includes(a)) {
              return true;
            }
          }
          return false;
        });
      }
      case 'status': {
        return txns.filter((t) => {
          return values.includes(t.categorizationStatus);
        });
      }
    }
    return [];
  }

  public uniqueColumnValues: { [id: string]: Array<string | number> } = {};
  public filters: any = {};
  public sort: any = {};

  onSort(sort: any) {
    this.sort = sort;
    this.callRestEndpoint();
  }

  onFilter(filters: any) {
    this.filters = filters;
    this.callRestEndpoint();
  }

  splitDate(date: number) {
    const formattedDate = moment.unix(date).tz(this.preferredTimezone).format('MM/DD/YY hh:mm a');
    const splitDate = formattedDate?.split(' ') || [];
    const dateParts = [];
    if (splitDate.length > 0) {
      dateParts.push(splitDate[0]);
      dateParts.push(splitDate[1] + ' ' + splitDate[2]);
    }
    return dateParts;
  }

  getStaticUnique(field: string): Array<string | number> {
    const txns = transactionResponse().txns;
    function onlyUnique(value: any, index: any, array: any) {
      return array.indexOf(value) === index;
    }

    switch (field) {
      case 'type': {
        return txns
          .map((t) => t.type)
          .filter(onlyUnique)
          .sort();
      }
      case 'txnId': {
        return txns
          .map((t) => t.id)
          .filter(onlyUnique)
          .sort();
      }
      case 'date': {
        return txns
          .map((t) => t.created)
          .sort()
          .map((t) => this.splitDate(t)[0])
          .filter(onlyUnique);
      }
      case 'wallet': {
        return txns
          .map((t) => {
            return t.txnLines.map((l) => l.walletId);
          })
          .flat()
          .filter(onlyUnique)
          .map((t) => {
            return (
              this.getStaticDemoWallets().data.wallets.find((w) => w?.id?.toLowerCase() === t.toLowerCase())?.name ?? ''
            );
          })
          .sort();
      }
      case 'toAddress': {
        return txns
          .map((t) => {
            return t.txnLines.map((l) => l.to);
          })
          .flat()
          .filter(onlyUnique)
          .sort();
      }
      case 'fromAddress': {
        return txns
          .map((t) => {
            return t.txnLines.map((l) => l.from);
          })
          .flat()
          .filter(onlyUnique)
          .sort();
      }
      case 'amount': {
        return txns
          .map((t) => {
            return t.txnLines.map((l) => l.amount);
          })
          .filter((x) => x)
          .flat()
          .filter(onlyUnique)
          .sort();
      }
      case 'status': {
        return txns.map((t) => t.categorizationStatus).filter(onlyUnique);
      }
    }
    return [];
  }

  getStaticDemoTransaction(
    limit = 10,
    offset = 0,
    type?: Array<string>,
    txnId?: Array<string>,
    date?: Array<number>,
    wallet?: Array<string>,
    toAddress?: Array<string>,
    fromAddress?: Array<string>,
    amount?: Array<string>,
    status?: Array<string>,
    orderBy?: string,
    orderDirection?: 'asc' | 'desc'
  ) {
    const txns = transactionResponse();
    const ret = {
      coins: txns.coins,
      txns: txns.txns,
    };
    if (type || txnId || date || wallet || toAddress || fromAddress || amount || status) {
      if (type) {
        ret.txns = this.filterStaticTxns('type', type, ret.txns);
      }
      if (txnId) {
        ret.txns = this.filterStaticTxns('txnId', txnId, ret.txns);
      }
      if (date) {
        ret.txns = this.filterStaticTxns('date', date, ret.txns);
      }
      if (wallet) {
        ret.txns = this.filterStaticTxns('wallet', wallet, ret.txns);
      }
      if (toAddress) {
        ret.txns = this.filterStaticTxns('toAddress', toAddress, ret.txns);
      }
      if (fromAddress) {
        ret.txns = this.filterStaticTxns('fromAddress', fromAddress, ret.txns);
      }
      if (amount) {
        ret.txns = this.filterStaticTxns('amount', amount, ret.txns);
      }
      if (status) {
        ret.txns = this.filterStaticTxns('status', status, ret.txns);
      }
    }

    if (orderBy) {
      const direction = orderDirection || 'desc';
      switch (orderBy) {
        case 'type': {
          ret.txns.sort((a, b) => {
            if (direction === 'desc') {
              return b.type.localeCompare(a.type);
            }
            return a.type.localeCompare(b.type);
          });
          break;
        }
        case 'id': {
          ret.txns.sort((a, b) => {
            if (direction === 'desc') {
              return b.id.localeCompare(a.id);
            }
            return a.id.localeCompare(b.id);
          });
          break;
        }
        case 'date': {
          ret.txns.sort((a, b) => {
            if (direction === 'desc') {
              return b.created - a.created;
            }
            return a.created - b.created;
          });
          break;
        }
        case 'wallets': {
          ret.txns.sort((a, b) => {
            if (direction === 'desc') {
              return b.txnLines[0]?.walletId.localeCompare(a.txnLines[0]?.walletId || '');
            }
            return a.txnLines[0]?.walletId.localeCompare(b.txnLines[0]?.walletId || '');
          });
          break;
        }
        case 'to': {
          ret.txns.sort((a, b) => {
            if (direction === 'desc') {
              return (b.txnLines[0]?.to || '').localeCompare(a.txnLines[0]?.to || '');
            }
            return (a.txnLines[0]?.to || '').localeCompare(b.txnLines[0]?.to || '');
          });
          break;
        }
        case 'from': {
          ret.txns.sort((a, b) => {
            if (direction === 'desc') {
              return (b.txnLines[0]?.from || '').localeCompare(a.txnLines[0]?.from || '');
            }
            return (a.txnLines[0]?.from || '').localeCompare(b.txnLines[0]?.from || '');
          });
          break;
        }
        case 'amounts': {
          ret.txns.sort((a, b) => {
            if (direction === 'desc') {
              const bLarge = Number(b.txnLines?.map((x) => parseFloat(x.amount)).sort()[b.txnLines.length - 1] || 0);
              const aLarge = Number(a.txnLines?.map((x) => parseFloat(x.amount)).sort()[a.txnLines.length - 1] || 0);
              return bLarge - aLarge;
            }
            const bSmall = Number(b.txnLines?.map((x) => parseFloat(x.amount)).sort()[0] || 0);
            const aSmall = Number(a.txnLines?.map((x) => parseFloat(x.amount)).sort()[0] || 0);
            return aSmall - bSmall;
          });
          break;
        }
        case 'status': {
          ret.txns.sort((a, b) => {
            if (direction === 'desc') {
              return b.categorizationStatus.localeCompare(a.categorizationStatus);
            }
            return a.categorizationStatus.localeCompare(b.categorizationStatus);
          });
          break;
        }
      }
    }
    ret.txns = ret.txns.slice(offset, offset + limit - 1);
    return ret;
  }

  getStaticDemoWallets() {
    const wallets = walletResponse();
    return wallets;
  }

  onPaginateClick(type: 'earlier' | 'later') {
    const paginateFn = () => {
      this.selectedTxns = [];
      this.onSelectionChanged(this.selectedTxns);
      const lastPaginateToken = this.vars.paginationToken ?? undefined;
      if (type === 'earlier') {
        this.vars.paginationToken = this.transactionsLite?.olderPageToken ?? lastPaginateToken;
      } else {
        this.vars.paginationToken = this.transactionsLite?.newerPageToken ?? lastPaginateToken;
      }
    };

    if (this.selectedTxns.length > 0) {
      const hasConfirmation = confirm(
        `You have (${this.selectedTxns.length}) selected transactions. This action will reset selected transactions.`
      );
      if (!hasConfirmation) return;
    }

    paginateFn();
  }
  // #endregion
}
