













































































































































































































































































































































































































































































































































































































































































































import * as math from 'mathjs';
import moment from 'moment-timezone';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import {
  CategorizationStatus,
  Contact,
  NetworkContactInput,
  NetworkFeeCategoryInput,
  ReconciliationStatus,
  ScopeLiterals,
  TransactionLineView,
  TransactionLite,
  TransactionView,
  TxnLineOperation,
  TxnType,
  Wallet,
} from '@/api-svc-types';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
// model
import { RowAction, RowActionPlace, RowActionType } from '@/models/uiDataTable';
import { convertUnits, getCoinFromEnumString, getCoinUnitFromEnumString, getMainUnitForCoin } from '@/utils/coinUtils';
import numberUtils from '@/utils/numberUtils';
import { checkScope } from '@/utils/security';

import { BaseVue } from '../../BaseVue';
import SaveInline from '../../components/transactions/SaveInline.vue';
import { isTxnClosed } from '../../services/transactionServices';
import UiModal from '../ui/UiModal.vue';
import EditTransactionModal from './edit/EditTransactionModal.vue';
import TransactionState from './TransactionState.vue';
import UiAdvancedDropdown from './UiAdvancedDropdown.vue';

interface TxnLineVM extends TransactionLineView {
  category: string;
  contact: string;
  feeCategory: string;
  feeContact: string;
  dirty: boolean;
}
export interface TxnVM extends TransactionView {
  txnLines: TxnLineVM[];
  canInline: boolean;
  useLines: boolean;
  accountingConnectionId: string;

  // FIXME: check after MR
  isManual?: boolean;
}

@Component({
  components: {
    UiModal,
    UiDataTable,
    UiTooltip,
    UiDropdown,
    UiLoading,
    SaveInline,
    UiSelect2,
    UiAdvancedDropdown,
    UiButton,
    EditTransactionModal,
    TransactionState,
  },
})
export default class TransactionTable extends BaseVue {
  @Prop() setDirtyTxns!: string[]; // used only for expanded row/categorization
  @Prop({ type: Array, required: true }) transactions!: TxnVM[]; // all transactionLites with txnLines populated ontop
  @Prop({ type: Array, required: true }) selectedTxns!: TxnVM[];
  @Prop({ default: false }) isLoading?: boolean;
  @Prop({ default: '0px' }) headerHeight?: string; // height of parent header to compensate for
  @Prop() wallets!: Wallet[]; // all wallets, used for table and categorization
  @Prop() categories!: any[]; // all categories, used for table and categorization
  @Prop() contacts!: Contact[]; // all contacts, used for table and categorization
  @Prop() feeCategories!: NetworkFeeCategoryInput[]; // default fee categories for organization
  @Prop() feeContacts!: NetworkContactInput[]; // default fee contacts for organization
  @Prop() coinLookup!: Map<string, string>; // currency code to coin name lookup
  @Prop() networkLookup!: Map<string, string>; // currency code to network id lookup
  @Prop() isLoadingExchangeRates!: boolean;
  @Prop({ required: true })
  public readonly getContact?: (transaction: TxnVM) => string; // function to get contact id from transaction only used for categorization

  @Prop({ required: true })
  public readonly isValidInline!: (txn: any) => boolean; // function to check if transaction is valid for inline categorization

  @Prop({ required: true })
  public readonly isDirty!: (txn: any) => boolean; // function to check if transaction is dirty for inline categorization

  @Prop({ default: 'all' })
  public readonly displayMode!: string; // table display mode

  @Prop({ default: false }) isError?: boolean;

  @Prop({ required: true })
  public readonly visibleHeaders!: string[]; // current visible headers

  @Prop({ required: true })
  public readonly headers!: any[]; // all available headers

  @Prop({ default: true })
  public readonly showActionColumn!: boolean; // show action column

  @Prop({ default: () => [] })
  public readonly connections!: any[]; // all connections, only used for inline categorization

  @Prop({ default: () => ({}) })
  public readonly uniqueColumnValues!: any; // for filters, a key value map where the key is the column name and the value is an array of unique values for that column

  @Prop({ default: () => ({}) })
  public filters!: { [col: string]: string[] }; // for filters, a key value map where the key is the column name and the value is an array of selected values for that column

  @Prop({ default: () => ({}) })
  public readonly sort!: any; // for sorting, an object with column name and sort direction

  @Prop({ default: () => ({ currency: 'USD', symbol: '$' }) })
  public readonly currentFiat!: { currency: string; symbol: string }; // current fiat currency

  @Prop()
  public readonly disabledDates!: { startDate?: string; endDate?: string };

  public selectedRow: HTMLElement | null = null; // ref to the selected row, used for scrolling and styling
  public expandedTxn: TxnVM | null = null;

  public numFormat = numberUtils.format; // number formatter

  public maxTableHeight = '42rem';

  public editTransactionOpen = false;

  public openEditTransaction() {
    this.editTransactionOpen = true;
  }

  public handleCloseEditTransaction() {
    this.editTransactionOpen = false;
  }

  public isManual(txn: TransactionLite) {
    return txn.isManual;
  }

  // #region general functions and computed properties
  public get dataTableActions() {
    const retVal = [
      ...(checkScope(ScopeLiterals.TransactionsCreate)
        ? [
            {
              label: 'Create transactions',
              value: () => this.$emit('createTransactionModal'),
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionsUpdate)
        ? [
            {
              label: 'Combine transactions',
              value: () => this.$emit('combineSelectedTransactions'),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionsDelete)
        ? [
            {
              label: 'Delete transactions',
              value: () => this.$emit('execDeleteSelectedTransactions'),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionsDelete)
        ? [
            {
              label: 'Un-Delete transactions',
              value: () => this.$emit('unDeleteSelectedTransactions'),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionsUpdate)
        ? [
            {
              label: 'Ignore transactions',
              value: () => this.$emit('setIgnoreSelectedTransactions', true),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionsUpdate)
        ? [
            {
              label: 'Un-ignore transactions',
              value: () => this.$emit('setIgnoreSelectedTransactions', false),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionReconcileUpdate)
        ? [
            {
              label: 'Un-reconcile transactions',
              value: () => this.$emit('setReconcileSelectedTransactions', ReconciliationStatus.Unreconciled),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
      ...(checkScope(ScopeLiterals.TransactionCategorizeUpdate)
        ? [
            {
              label: 'Un-categorize transactions',
              value: () => this.$emit('uncategorizeTxns'),
              disabled: !this.selectedTxns.length,
            },
          ]
        : []),
    ];

    if (this.allowBulkCategorize && this.checkScope(ScopeLiterals.TransactionCategorizeUpdate)) {
      retVal.push({
        // label: `Bulk Categorize transactions ${this.selectedTxns.length ? `(${this.selectedTxns.length})` : ''}`,
        label: `Categorize`,
        value: () => this.$emit('bulkEditTransaction'),
        disabled: !this.selectedTxns.length,
      });
    }

    return retVal;
  }

  // ui: new, show row actions
  public get dataTableRowActions(): RowAction[] {
    const actions: RowAction[] = [];

    if (this.allowBulkCategorize && this.checkScope(ScopeLiterals.TransactionCategorizeUpdate)) {
      actions.push({
        label: `Categorize`,
        value: () => this.$emit('bulkEditTransaction'),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        sortIndex: 0,
      });
    }

    if (checkScope(ScopeLiterals.TransactionsUpdate)) {
      actions.push({
        label: 'Ignore',
        value: () => this.$emit('setIgnoreSelectedTransactions', true),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        sortIndex: 1,
        separator: true,
      });

      actions.push({
        label: 'Un-ignore',
        value: () => this.$emit('setIgnoreSelectedTransactions', false),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 2,
      });

      actions.push({
        label: 'Combine',
        value: () => this.$emit('combineSelectedTransactions'),
        disabled: !this.selectedTxns.length || this.selectedTxns.length < 2,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        sortIndex: 2,
      });

      actions.push({
        label: 'Un-combine',
        value: () => this.$emit('uncombineTxn', this.selectedTxns[0]),
        disabled:
          !this.selectedTxns.length || this.selectedTxns.length !== 1 || !this.selectedTxns[0]?.isCombinedParent,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        sortIndex: 3,
        separator: true,
      });

      actions.push({
        label: 'Edit',
        value: () => this.$emit('editSingleTransaction'),
        disabled: !this.selectedTxns.length || this.selectedTxns.length !== 1 || !this.selectedTxns[0]?.isManual,
        type: RowActionType.Button,
        place: [RowActionPlace.RowActions],
        sortIndex: 4,
      });

      actions.push({
        label: 'Un-categorize',
        value: () => this.$emit('uncategorizeTxns'),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 3,
      });

      if (this.checkFeatureFlag('event-sourced-txns')) {
        actions.push({
          label: 'Sync',
          value: () => this.$emit('syncTransactions'),
          disabled: !this.selectedTxns.length,
          type: RowActionType.Dropdown,
          place: [RowActionPlace.RowActions],
          sortIndex: 5,
        });

        actions.push({
          label: 'Cancel Sync',
          value: () => this.$emit('cancelTransactionsSync'),
          disabled: !this.selectedTxns.length,
          type: RowActionType.Dropdown,
          place: [RowActionPlace.RowActions],
          sortIndex: 6,
        });
      }
    }

    if (checkScope(ScopeLiterals.TransactionsDelete)) {
      actions.push({
        label: 'Delete',
        value: () => this.$emit('execDeleteSelectedTransactions'),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 1,
      });
      actions.push({
        label: 'Un-Delete',
        value: () => this.$emit('unDeleteSelectedTransactions'),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 1,
      });
    }

    if (checkScope(ScopeLiterals.TransactionReconcileUpdate)) {
      actions.push({
        label: 'Mark as Reconciled',
        value: () => this.$emit('setReconcileSelectedTransactions', ReconciliationStatus.Reconciled),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 4,
      });

      actions.push({
        label: 'Un-reconcile',
        value: () => this.$emit('setReconcileSelectedTransactions', ReconciliationStatus.Unreconciled),
        disabled: !this.selectedTxns.length,
        type: RowActionType.Dropdown,
        place: [RowActionPlace.RowActions],
        sortIndex: 4,
      });
    }

    return actions;
  }

  public get walletById() {
    return this.wallets.reduce((a, x) => {
      a[x.id as string] = x;
      return a;
    }, {} as Record<string, Wallet>);
  }

  public get walletIdByAddress() {
    return this.wallets.reduce((a, x) => {
      x.addresses?.forEach((y) => {
        y = y?.toLowerCase() ?? '';
        if (y && x.id) {
          if (!a[y]) {
            a[y] = [];
          }
          a[y?.toLowerCase()].push(x.id);
        }
      });
      return a;
    }, {} as Record<string, string[]>);
  }

  public get preferredTimezone() {
    const useOrgTimezone = this.$store.state.currentOrg.displayConfig?.useOrgTimezone ?? false;
    return useOrgTimezone ? this.$store.state.currentOrg.timezone : moment.tz.guess();
  }

  public getConvertedValue(amount: any) {
    const coin = getCoinFromEnumString(amount.coin);
    const mainUnit = getMainUnitForCoin(coin ?? '');
    const val = this.convertBigNumberScalar(amount.value);
    const unit = getCoinUnitFromEnumString(amount.unit);
    let conv;
    if (unit && mainUnit && coin) {
      conv = convertUnits(coin, unit, mainUnit, val);
    }
    return conv ?? val;
  }

  public getFmv(amount: number, txn: TxnVM, coin: string) {
    //   const rates = txn.accountingDetails?.[0]?.exchangeRates?.length
    //     ? txn.accountingDetails?.[0]?.exchangeRates
    //     : txn.exchangeRates?.exchangeRates;
    //   const rate = rates?.find((x) => x?.coin === coin);
    //   return amount * parseFloat(rate?.rate || '0');
    return '0';
  }

  public onSelectionChanged(selectedItems: any[]) {
    this.$emit('selectionChanged', selectedItems);
  }

  public refresh() {
    this.$emit('refresh');
  }

  public async savedRow({ id }: { id: string }) {
    if (id) {
      const txn = this.transactions.find((x) => x.id === id);
      if (txn) txn.categorizationStatus = 'Categorized';
    }
    this.expandedTxn = null;
    this.selectedRow?.classList.add('row-transition');
    await this.$nextTick();
    this.selectedRow?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.selectedRow?.classList.add('tw-bg-success-100');
    setTimeout(() => {
      this.selectedRow?.classList.remove('tw-bg-success-100');
    }, 1000);
  }

  public handleActionOnTable(handler: string | unknown) {
    if (typeof handler !== 'string') {
      (handler as () => void)();
    }
  }

  public toggleRowView({ item, row }: { item: TxnVM; row: HTMLElement }) {
    this.selectedRow = row;
    if (this.expandedTxn && this.expandedTxn.id === item.id) {
      this.expandedTxn = null;
    } else {
      this.expandedTxn = item;
      window.pendo?.track('Transaction - Transaction Expanded', {
        useLines: item.useLines,
        canInline: item.canInline,
        categorizationStatus: item.categorizationStatus,
        reconciliationStatus: item.reconciliationStatus,
        isCombined: item.isCombinedParent,
        ignored: item.ignored,
      });
    }
  }

  public singleLink(txn: TxnVM) {
    const metadata = this.displayMetadata(txn.metadata);
    const viewLink = txn.txViewLink;
    const txnUrl = metadata?.find((x: { key: string; value: string }) => x.key.toLowerCase() === 'txnurl')?.value;
    if (txnUrl) {
      return txnUrl;
    } else {
      return viewLink;
    }
  }

  public multiLink(txn: TxnVM) {
    const viewLinks = txn.txViewLink;
    if (viewLinks && viewLinks.length > 1) {
      return viewLinks;
    } else {
      return undefined;
    }
  }

  public getIconsForTxn(txn: TxnVM) {
    const icons = [] as { icon: string; class: string; text: string; position?: string }[];
    const iconDatas = {
      [TxnType.Receive]: {
        icon: 'fa-arrow-circle-left',
        class: 'tw-text-success-300 tw-transform tw--rotate-45',
        text: 'Received',
      },
      [TxnType.Send]: {
        icon: 'fa-arrow-circle-right',
        class: `tw-text-error-300 tw-transform tw--rotate-45`,
        text: 'Send',
      },
      [TxnType.Trade]: {
        icon: 'fa-exchange',
        class: 'tw-text-primary-300',
        text: 'Trade',
      },
      [TxnType.Transfer]: {
        icon: 'fa-refresh',
        class: 'tw-text-primary-300',
        text: 'Transfer',
      },
      [TxnType.ContractExecution]: {
        icon: 'fa-code',
        class: 'tw-text-primary-300',
        text: 'Contract execution',
      },
    } as Record<TxnType, typeof icons[number]>;
    if (txn.transactionType) {
      if (txn.transactionType in iconDatas) {
        icons.push(iconDatas[txn.transactionType]);
      }
      //   if (txn.type === TxnType.Receive && txn.hasMatchedInvoices) {
      //     icons.push({
      //       icon: 'fa-credit-card-alt',
      //       class: 'tw-text-primary-300',
      //       text: 'Invoice payment',
      //     });
      //   }
    }
    if (txn.isCombinedParent) {
      icons.push({
        icon: 'fa-compress',
        class: 'tw-text-primary-300',
        text: 'Combined transaction',
      });
    }
    // if (txn.isCombinedSubTransaction) {
    //   icons.push({
    //     icon: 'fa-sign-in',
    //     class: 'tw-text-primary-300 tw-transform tw-rotate-180',
    //     text: 'Combined sub-transaction',
    //   });
    // }
    // if (txn.errored) {
    //   icons.push({
    //     icon: 'fa-exclamation-circle',
    //     class: 'tw-text-error-300',
    //     text: 'Errored', // txn.errors?.join('\n') ?? 'Errored',
    //   });
    // }
    // remove for now
    // if (!txn.useLines) {
    //   icons.push({
    //     icon: 'fa-exclamation-circle',
    //     class: 'tw-text-gray-300',
    //     text: 'This transaction cannot be inline categorized',
    //     position: 'right',
    //   });
    // }
    return icons;
  }

  public getWalletNameByAddress(address: string, walletId: string) {
    return this.walletIdByAddress?.[address.toLowerCase()]?.some((y) => y === walletId)
      ? this.walletById?.[walletId]?.name
      : '';
  }

  public lookupCoin(currencyId: string) {
    return this.$store.state.networks?.find((x: any) => x.networkId === currencyId)?.networkId ?? currencyId;
  }

  public lookupNetwork(currencyId: string) {
    return this.networkLookup.get(currencyId) ?? currencyId;
  }

  public displayMetadata(metadata: any) {
    const metadataArray =
      Object.keys(metadata ?? {}).map((key) => {
        return { key: key, value: metadata[key] };
      }) || [];
    return metadataArray;
  }

  public isTxnClosed(txn: TxnVM): boolean {
    return false; // isTxnClosed(txn, this.$store.state.currentOrg);
  }

  public splitDate(date: string) {
    const formattedDate = moment.utc(date).tz(this.preferredTimezone).format('MM/DD/YY hh:mm a');
    const splitDate = formattedDate?.split(' ') || [];
    const dateParts = [];
    if (splitDate.length > 0) {
      dateParts.push(splitDate[0]);
      dateParts.push(splitDate[1] + ' ' + splitDate[2]);
    }
    return dateParts;
  }

  public getAddressFirstPart(address: string) {
    return address?.substring(0, address.length - 8) ?? '';
  }

  public getAddressLastPart(address: string) {
    return address?.substring(address.length - 8, address.length) ?? '';
  }
  // #endregion

  // #region sorting filtering

  public onSort(sort: any) {
    this.$emit('sort', sort);
  }

  public onSearch(value: { search: string; column: string }) {
    this.$emit('search', value);
  }

  public onFilter(filters: any) {
    this.$emit('filter', filters);
  }

  public clearFilter() {
    (this.$refs?.dataTable as any)?.clearFilter();
  }

  public clearSort() {
    (this.$refs?.dataTable as any)?.clearSort();
  }

  // #endregion

  // #region txnLines vs fullAmountSet

  public useLines(txn: TxnVM) {
    return this.txnLines[txn.id ?? '']?.length && this.verifyLines(txn);
  }

  public getMaxTableHeight() {
    if (window.self !== window.top) {
      return '100%';
    }

    const viewportHeight = window.innerHeight;

    const mainNavTopbar = document.querySelector('#main-nav-topbar') as HTMLElement;
    const txnsPageHeader = document.querySelector('#txns-page-header') as HTMLElement;
    const header = document.querySelector('#txns-table-header') as HTMLElement;

    const mainNavTopbarHeight = mainNavTopbar ? mainNavTopbar.offsetHeight : 0;
    const txnsPageHeaderHeight = txnsPageHeader ? txnsPageHeader.offsetHeight : 0;
    const headerHeight = header ? header.offsetHeight : 0;

    const otherElementsHeight = mainNavTopbarHeight + txnsPageHeaderHeight + headerHeight;
    const tableHeight = viewportHeight - otherElementsHeight;

    return tableHeight + 'px';
  }

  public verifyLines(txn: TxnVM) {
    return true;
  }

  get txnLines() {
    return this.transactions.reduce((a: any, x) => {
      if (x.id) a[x.id] = this.getSortedTxnLines(x as TxnVM);
      return a;
    }, {});
  }

  getSortedTxnLines(txn: TxnVM) {
    // sort the txn lines placing the ones with feeAmount or operation equals 'FEE' at the end also secondary sort by walletId, do not mutate the original array
    return (
      (txn.lines?.slice()?.sort((a, b) => {
        if (a?.operation === 'FEE' && b?.operation !== 'FEE') {
          return 1;
        } else if (a?.operation !== 'FEE' && b?.operation === 'FEE') {
          return -1;
        } else if ((a?.walletId || '') > (b?.walletId || '')) {
          return 1;
        } else if ((a?.walletId || '') < (b?.walletId || '')) {
          return -1;
        } else {
          return 0;
        }
      }) as any[]) ?? []
    );
  }

  // #endregion

  // #region inline logic

  public canInline(txn: TxnVM) {
    if (!this.checkFeatureFlag('babel-inline-categorization')) return false;
    return txn.transactionType === TxnType.Receive || txn.transactionType === TxnType.Send;
  }

  public handleConnectionSelected(txn: TxnVM, connection: any) {
    if (txn.accountingConnectionId !== connection) {
      txn.lines.forEach((x: TransactionLineView) => {
        // use this.$set to trigger reactivity
        this.$set(x, 'category', '');
        this.$set(x, 'contact', '');
      });
    }
    this.$set(txn, 'accountingConnectionId', connection);
  }

  public setLineDirty(line: any, dirty = false) {
    line.dirty = dirty;
  }

  public calcCategory(line: any, txn: TxnVM) {
    if (line.operation === TxnLineOperation.Fee) {
      return this.knownFeeCategory(line, txn);
    } else {
      return this.knownCategory(line, txn);
    }
  }

  public knownCategory(line: any, txn: TxnVM) {
    const categoryType = txn.transactionType === TxnType.Send ? 'defaultExpenseCategoryId' : 'defaultRevenueCategoryId';
    const categoryId = line.category;
    if (!categoryId) {
      const catCategoryId =
        txn.categorizationStatus === CategorizationStatus.Uncategorized
          ? this.getContactsByConnection(txn.accountingConnectionId ?? '').find((x) => x.id === line.contact)?.[
              categoryType
            ]
          : '';
      this.$set(line, 'category', catCategoryId);
    }
    return line.category;
  }

  public knownFeeCategory(line: any, txn: TxnVM) {
    if (!line.category) {
      let categoryId = '';
      if (txn.categorizationStatus === CategorizationStatus.Uncategorized) {
        categoryId = this.$store.state.currentOrg.accountingConfig?.defaultFeeCategoryIds?.[0]?.categoryId ?? '';
        if (!this.getCategoriesByConnection(txn.accountingConnectionId ?? '').some((x) => x.id === categoryId)) {
          categoryId = '';
        }
      } else {
        categoryId = '';
      }
      this.$set(line, 'category', categoryId);
    }
    return line.categoryId;
  }

  public knownAddress(line: any, txn: TxnVM) {
    const address = txn.transactionType === TxnType.Receive ? line.from : line.to;
    const contactId = line.contact;
    const assetId = line.networkId;
    if (!contactId) {
      const catContactId = '';
      const defaultFeeContact =
        line.operation === 'FEE' && this.canInline(txn)
          ? this.feeContacts.find((x) => x.blockchain?.toLowerCase() === this.lookupCoin(assetId)?.toLowerCase())
              ?.contactId ?? ''
          : '';
      const contact = this.getContactsByConnection(txn.accountingConnectionId ?? '').find((x) =>
        txn.categorizationStatus === CategorizationStatus.Uncategorized
          ? x.addresses.some((y) => y.address === address) || defaultFeeContact === x.id
          : x.id === catContactId
      );
      if (contact) this.$set(line, 'contact', contact.id);
    }
    return line.contact;
  }

  public knownFeeAddress(line: any, txn: TxnVM) {
    // const address = txn.transactionType === TxnType.Receive ? line.from : line.to;
    // const contactId = line.feeContact;
    // const assetId = line.feeAssetId;
    // if (!contactId) {
    //   const catContactId = ''
    //   const defaultFeeContact = txn.canInline
    //     ? this.feeContacts.find((x) => x.blockchain?.toLowerCase() === this.lookupCoin(assetId)?.toLowerCase())
    //         ?.contactId ?? ''
    //     : '';
    //   const contact = this.getContactsByConnection(txn.accountingConnectionId ?? '').find((x) =>
    //     txn.categorizationStatus === CategorizationStatus.Uncategorized
    //       ? x.addresses.some((y) => y.address === address && y.coin === this.lookupCoin(assetId)) ||
    //         defaultFeeContact === x.id
    //       : x.id === catContactId
    //   );

    //   if (contact) this.$set(line, 'feeContact', contact.id);
    // }

    return line.contact;
  }

  public getCategoryName(categoryId: string) {
    const category = this.categories.find((x) => x.id === categoryId);
    return category?.name ?? '';
  }

  public getContactName(contactId: string) {
    const contact = this.contacts.find((x) => x.id === contactId);
    return contact?.name ?? '';
  }

  public getCategoriesByConnection(accountingConnectionId: string) {
    return this.categories.filter((x) => x.accountingConnectionId === accountingConnectionId);
  }

  public getContactsByConnection(accountingConnectionId: string) {
    return this.contacts.filter((x) => x.accountingConnectionId === accountingConnectionId);
  }

  public inlineCategorize(txn: any) {
    this.$emit('inlineCategorize2', txn);
  }

  public assignToContact(address: string, contactId: string, coin: string) {
    this.$emit('assignToContact', { address, contactId, currencyId: coin });
    this.initLinesForInline();
  }

  public assignCategoryToContact(categoryId: string, contactId: string, revenue: TxnType) {
    this.$emit('assignCategoryToContact', {
      categoryId,
      contactId,
      revenue: revenue === TxnType.Receive,
    });
    this.initLinesForInline();
  }

  public closeAdvDropdown(key: string) {
    ((this.$refs[key] as Vue[])[0] as any)?.setOpen();
  }

  public initLinesForInline() {
    // if (!this.checkFeatureFlag('babel-inline-categorization')) return;
    this.transactions.forEach((x) => {
      x.accountingConnectionId =
        x.accountingConnectionId ?? (this.connections.find((cn) => cn.isDefault) || this.connections?.[0])?.id;
      x.lines.forEach((y) => {
        if (y?.amount?.startsWith('-')) {
          y.amount = y.amount?.slice(1); // remove minus sign to keep precision and not convert to number.
        }
        if (y.operation === TxnLineOperation.Fee) {
          this.knownAddress(y, x);
          this.knownFeeCategory(y, x);
        } else {
          this.knownAddress(y, x);
          this.knownCategory(y, x);
        }
      });
      x.canInline = this.canInline(x);
      x.useLines = this.verifyLines(x);
    }, {});
  }

  // #endregion

  // #region bulk categorize

  public get allowBulkCategorize() {
    return true; // bulkcategorize was the flag
  }

  // #endregion

  // #region lifeCycleHooks
  @Watch('transactions')
  @Watch('contacts')
  @Watch('categories')
  public onTransactionsChanged() {
    this.initLinesForInline();

    const triggerPlaceholder = this.txnLines; // trigger computed property, the assignment is to avoid linting error for raw expression
  }

  public mounted() {
    this.maxTableHeight = this.getMaxTableHeight();
  }
  // #endregion
}
